import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {PasswordFormI} from '@components/password-creation';
import {cleanObject} from '@idapgroup/js-object-utils';
import {loader} from '@modules/custom-loader/models/decorators';
import {SocialUserServiceI} from '@modules/social-auth';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {TransferHttpService} from '@core/modules/transfer-http/transfer-http.service';
import {buildURLParams} from '@helpers/build-url-params';
import {httpParamsFromObject} from '@helpers/http-params';
import {
  AcceptPayloadDto,
  AuthBehavior,
  AuthDto,
  ClaimSupplierListParams,
  ClaimSupplierPayload,
  CompanyLoginDto,
  CompanyUserProfilePayload,
  LoginDto,
  PasswordRefreshDto,
  RegistrationAuthDto,
  RegistrationDto,
  RegistrationEmailCheckDto,
  RegistrationEmailCheckPayload,
  RegistrationTokenDto,
  ResetPasswordDto,
  SupplierClaimListDto,
  TokenDto,
  VerificationDto,
} from '@models/auth';
import {CrewContractsList, InvitedCrewDto} from '@models/crew/crew.model';
import {InvitationAnswer} from '@models/invitation';
import {InvitedUserListDto, InvitedUsersPayload} from '@models/invited-users/invited-users.model';
import {PermissionsDto, PermissionsPayload} from '@models/permissions';
import {ProfileGeneralFormDto, ProfileGeneralInfoDto, UpdateAvatarProfilePayload} from '@models/profile';
import {ResponseDto} from '@models/response';
import {
  ChangeUserDocumentStatusPayload,
  UserDocumentItemDto,
  UserDocumentListDto,
  UserDownloadDocumentsPayload,
  UserSubscribePayload,
  UserTokenSubscribePayload,
  UserUnitSystemPayload,
} from '@models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService implements SocialUserServiceI {

  private readonly apiUrl = 'api/user';

  constructor(private readonly http: TransferHttpService) {
  }

  register(payload: RegistrationDto): Observable<AuthDto> {
    return this.http.post<ResponseDto<AuthDto>>(`${this.apiUrl}/registration`, payload)
      .pipe(
        map(res => res.data),
      );
  }

  registrationVerification(payload: VerificationDto): Observable<AuthDto> {
    const params = httpParamsFromObject(payload);
    return this.http.get<ResponseDto<AuthDto>>(`${this.apiUrl}/registration-verification-email`, {params})
      .pipe(
        map(res => res.data),
      );
  }

  @loader()
  emailVerification(payload: RegistrationEmailCheckPayload): Observable<RegistrationEmailCheckDto> {
    return this.http.post<ResponseDto<RegistrationEmailCheckDto>>(`${this.apiUrl}/check-user-exist-by-email`, payload)
      .pipe(
        map(res => res.data),
      );
  }

  login(payload: LoginDto): Observable<AuthDto> {
    return this.http.post<ResponseDto<AuthDto>>(`${this.apiUrl}/login`, payload)
      .pipe(
        map(res => res.data),
      );
  }

  getCurrentUser(behavior: AuthBehavior | null): Observable<AuthDto> {
    const params = httpParamsFromObject({behavior});
    return this.http.get<ResponseDto<AuthDto>>(`${this.apiUrl}/get-current-user`, {params}).pipe(
      map(response => response.data),
    );
  }

  updatePassword(payload: PasswordRefreshDto): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/set-reset-password`, payload);
  }

  getEmailForReset(payload: ResetPasswordDto): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/get-reset-password`, payload);
  }

  logout(): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/logout`, {});
  }

  getInvitedUser(payload: RegistrationTokenDto): Observable<ResponseDto<InvitedCrewDto>> {
    return this.http.post(`${this.apiUrl}/get-user-payload-after-invitation`, payload);
  }

  registerInvitedUser(payload: RegistrationDto): Observable<AuthDto> {
    return this.http.post<ResponseDto<AuthDto>>(`${this.apiUrl}/set-user-payload-after-invitation`, payload).pipe(
      map(res => res.data),
    );
  }

  confirmInvitation(contractId: number, answer: InvitationAnswer): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/set-answer-invitation-to-boat`, {contractId, answer});
  }

  getUserGeneralInfo(): Observable<ProfileGeneralInfoDto> {
    return this.getUserData('get-view-profile');
  }

  getUserGeneralForm(): Observable<ProfileGeneralFormDto> {
    return this.getUserData('get-form-profile');
  }

  updateProfile(payload: Params): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/edit-profile`, payload);
  }

  userContracts(): Observable<CrewContractsList> {
    return this.getUserData('get-view-contract');
  }

  updateProfileAvatar(payload: UpdateAvatarProfilePayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/change-avatar`, payload);
  }

  loadBoatsPermissions(payload: PermissionsPayload): Observable<PermissionsDto[]> {
    const params = buildURLParams(payload);
    return this.http.get<ResponseDto<PermissionsDto[]>>(`${this.apiUrl}/get-permissions`, {params}).pipe(
      map(response => response.data),
    );
  }

  private getUserData<T>(url: string): Observable<T> {
    return this.http.get<ResponseDto<T>>(`${this.apiUrl}/${url}`).pipe(
      map(response => response.data),
    );
  }

  resendVerification(email: string, companyId: number | null = null): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/resend-email-verify-account`, {email, companyId});
  }

  updateUserPassword(payload: Exclude<PasswordFormI, 'passwordRepeat'>): Observable<ResponseDto<TokenDto>> {
    return this.http.put(`${this.apiUrl}/change-password`, payload);
  }

  setUserPassword(payload: Exclude<PasswordFormI, 'passwordRepeat'>): Observable<ResponseDto<TokenDto>> {
    return this.http.put(`${this.apiUrl}/set-up-password`, payload);
  }

  registerUserWithCompany(payload: CompanyUserProfilePayload): Observable<ResponseDto> {
    return this.http.post<ResponseDto>(`${this.apiUrl}/registration-with-company`, payload);
  }

  registerSupplier(payload: RegistrationDto): Observable<RegistrationAuthDto> {
    return this.http.post<ResponseDto<RegistrationAuthDto>>(`${this.apiUrl}/registration-supplier`, payload)
      .pipe(
        map(res => res.data),
      );
  }

  loginUserWithCompany(payload: CompanyLoginDto): Observable<AuthDto> {
    return this.http.post<ResponseDto<AuthDto>>(`${this.apiUrl}/login-with-company`, payload)
      .pipe(
        map(response => response.data),
      );
  }

  verifyToken(): Observable<unknown> {
    return this.http.get(`${this.apiUrl}/check-token-expired`);
  }

  setUserUnitSystem(payload: UserUnitSystemPayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/set-units-system`, payload);
  }

  setSubscribeStatusByToken(payload: UserTokenSubscribePayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/subscribe-newsletter`, payload);
  }

  setSubscribeStatus(payload: UserSubscribePayload): Observable<ResponseDto> {
    return this.http.put(`${this.apiUrl}/change-subscribe-newsletter`, payload);
  }

  getDownloadFilesList(payload: UserDownloadDocumentsPayload): Observable<UserDocumentListDto> {
    const params = buildURLParams(payload);
    return this.http.get<ResponseDto<UserDocumentListDto>>(`${this.apiUrl}/get-queue-download-list-files`, {params})
      .pipe(
        map(res => res.data),
      );
  }

  setDocumentDownloadStatus(payload: ChangeUserDocumentStatusPayload): Observable<ResponseDto> {
    const params = buildURLParams(payload);
    return this.http.put<ResponseDto>(`${this.apiUrl}/set-download-file-status`, params);
  }

  getUnViewedDownloadFilesList(): Observable<UserDocumentItemDto[]> {
    return this.http.get<ResponseDto<UserDocumentItemDto[]>>(`${this.apiUrl}/get-unviewed-download-reports`)
      .pipe(
        map(res => res.data),
      );
  }

  setDocumentViewedStatus(payload: ChangeUserDocumentStatusPayload): Observable<ResponseDto> {
    const params = buildURLParams(payload);
    return this.http.put<ResponseDto>(`${this.apiUrl}/set-download-file-viewed`, params);
  }

  getInvitedUserList(payload: InvitedUsersPayload): Observable<InvitedUserListDto> {
    const params = buildURLParams(payload);
    return this.http.get<ResponseDto<InvitedUserListDto>>(`${this.apiUrl}/get-invited-by-me`, {params})
      .pipe(
        map(res => res.data),
      );
  }

  @loader()
  socialAuth<T, W>(payload: T): Observable<W> {
    return this.http.post<ResponseDto<W>>(`${this.apiUrl}/social-auth`, payload).pipe(
      map(response => response.data),
    );
  }

  // @loader()
  connectSocialNetwork(payload: any): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/connect-social-auth`, payload);
  }

  // @loader()
  disconnectSocialNetwork(payload: any): Observable<ResponseDto> {
    const params = httpParamsFromObject(payload);
    return this.http.delete(`${this.apiUrl}/disconnect-social-auth`, {params});
  }

  getClaimSupplierList(payload: ClaimSupplierListParams): Observable<SupplierClaimListDto> {
    const params = buildURLParams(cleanObject(payload));
    return this.http.post<ResponseDto<SupplierClaimListDto>>(`${this.apiUrl}/get-matched-supplier`, params)
      .pipe(
        map(res => res.data),
      );
  }

  claimSuppliers(payload: ClaimSupplierPayload): Observable<ResponseDto> {
    return this.http.post<ResponseDto>(`${this.apiUrl}/connect-matched-supplier`, payload);
  }

  acceptManageDocuments(payload: AcceptPayloadDto): Observable<ResponseDto> {
    return this.http.post(`${this.apiUrl}/accept-request-to-manage-documents`, payload);
  }
}
